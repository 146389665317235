/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
//import "gatsby-syntax-highlights-dracula"
import "./src/css/index.css"
const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    addScript("https://wn2t5bsywbmc.statuspage.io/embed/script.js")
  }
}
//require("prismjs/themes/prism-solarizeddark.css")
